import React, {Component} from 'react';
import SideMenu from '../admin/SideMenu';
import {db} from '../../base';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import {cleanCSVText, convertTimeStampToHumanReadable, findFirstAndLastName, sanitizeEmail} from 'myshared';
import DatePicker from '../utils/DatePicker';
const Papa = require('papaparse');

class AdminHome extends Component {
    constructor(props) {
        super(props);
        const todaysDateObject = new Date();
        todaysDateObject.setDate(todaysDateObject.getDate()-30);
        todaysDateObject.setHours(0,0,0,0); // Sets the time to start of the day (midnight)
        const endDateObject = new Date();
        endDateObject.setHours(23,59,59,999); // Sets the time to end of the day (11:59 pm)
        const splitHref = window.location.href.split("/");
        splitHref.pop();
        this.state = {
            games: 0,
            users: 0,
            itemsPerPage: 10,
            itemsToShow: 10,
            uniqueGamesUsers: 0,
            loading: false,
            players: 0,
            gameUsers: 0,
            prizesWon: 0,
            lastGame: {},
            uniqueGameUsers: false,
            gamesToShowList: [],
            searchStart: todaysDateObject,
            searchEnd: endDateObject
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value
        });
    }

    async componentDidMount(){
        // const prizeWonTemplate = {
        //     id: "aLoJBEgqGvesPlLuKNhu",
        //     isRedeemable: false,
        //     isWinning: true,
        //     revealImage: 'https://s3-us-west-2.amazonaws.com/scracthers-game/8c41808d-97d1-47e5-93aa-fca469ba40f8.jpg',
        //     rewardDisplayName: 'MOA® Mystery Prizes',
        //     rewardName: 'MOA® Mystery Prizes',
        //     hasCodes: true
        //     // codes: codesList
        // }
        this.getGames(this.state.searchStart.getTime(), this.state.searchEnd.getTime());
        this.getUsers(this.state.searchStart.getTime(), this.state.searchEnd.getTime());
        // await this.addRedeemedFalseToPrizeWon();
        // const codesSize = await db.collection('gamesList').doc("1gprTlSks45JRKWs8zwx").collection("rewards").doc("1ZVcYbnBwxyB0YhLtYEp").collection("codes").get();
        // console.log(codesSize.size)
        // fetch(testCsv)
        //     .then(r => r.text())
        //     .then(async text => {
        //         const splitText = text.split('\n');
        //         const promises = [];
        //         let batch = db.batch();
        //         let count = 0
        //         let batchUpdatesArray = [];
        //         for(const i in splitText){
        //             if(i < 1) continue;
        //             const row = splitText[i];
        //             const splitRow = row.split(',');
        //             console.log(splitRow[4])
        //             const requestCardsTime = splitRow[4];
        //             const emailSentTime = splitRow[1];
        //             const RedeemCode = splitRow[5];
        //             const prizeSent = splitRow[9];
        //             const email = splitRow[15];
        //             if(!email) continue;
        //             const gameId = "koUqBRXRz5kKc3IQ7Vlv";
        //             let newId = db.collection("prizeWon").doc().id;
        //             const userId = btoa(email.trim());
        //             const prizeWonObject = {
        //                 code: RedeemCode,
        //                 emailSentTime: new Date(emailSentTime).getTime(),
        //                 gameId: gameId,
        //                 prizeWonId: newId,
        //                 redeemed: false,
        //                 released: prizeSent === "1",
        //                 requestCardsTime: new Date(requestCardsTime).getTime(),
        //                 uid: userId,
        //                 prizeWon: prizeWonTemplate
        //             }
        //             break
        //             const prizeWonRef = db.collection("prizeWon").doc(newId);
        //             batch.set(prizeWonRef, prizeWonObject);
        //             const currentGameUserObject = await db.collection("currentGamePlayers").doc(userId).get();
        //             if(currentGameUserObject.exists){
        //                 const currentGamePlayersRef = db.collection("currentGamePlayers").doc(userId);
        //                 batch.update(currentGamePlayersRef, {numberOfPlays: currentGameUserObject.data().numberOfPlays + 1});
        //             } else {
        //                 const currentGamePlayersRef = db.collection("currentGamePlayers").doc(userId);
        //                 batch.set(currentGamePlayersRef, {id: userId, numberOfPlays: 1, timeStamp: new Date(requestCardsTime).getTime()});
        //             }
        //             const rewardsDocRef = db.collection("gamesList").doc(gameId).collection('rewards').doc('aLoJBEgqGvesPlLuKNhu');
        //             batch.update(rewardsDocRef, {usedTickets: count});
        //             // if (++count % 10 === 0) {
        //             //     await batch.commit();
        //             //     batch = db.batch();  // Start a new batch for the next iteration
        //             // }
        //         }
                // If there's a batch in progress that wasn't committed because we didn't reach another multiple of 50, commit it now
                // if (count % 10 !== 0) {
                //     await batch.commit();
                // }
        //         // await Promise.all(batchUpdatesArray)
        //         // await Promise.all(promises)
        //         console.log("Done")
        //     });
        // fetch(testCsv)
        //     .then(r => r.text())
        //     .then(async text => {
        //         const splitText = text.split('\n');
        //         const promises = [];
        //         let batch = db.batch();
        //         let count = 0
        //         let batchUpdatesArray = [];
        //         for(const i in splitText){
        //             if(i < 1) continue;
        //             const row = splitText[i];
        //             const splitRow = row.split(',')
        //             const gameId = splitRow[0];
        //             if(!gameId) continue;
        //             const dateObject = new Date(splitRow[3]);
        //             const name = splitRow[1];
        //             let signUpTime = dateObject.getTime();
        //             if(signUpTime < 0){
        //                 signUpTime = 0;
        //             }
        //             const sanitizedEmail = splitRow[4];
        //             const uid = splitRow[5];
        //             let requestCardsTime = splitRow[6];
        //             if(requestCardsTime){
        //                 requestCardsTime = parseInt(requestCardsTime)
        //             }
        //             const email = splitRow[7];
        //             const prizeWonId = splitRow[8];
        //             const rewardName = splitRow[9];
        //             let newId = db.collection("usersGameData").doc().id
        //             const gameToCreateObject = {
        //                 id: newId,
        //                 gameId: gameId,
        //                 email: email,
        //                 signUpTime: signUpTime,
        //                 sanitizedEmail: sanitizedEmail,
        //                 uid: uid,
        //                 requestCardsTime: requestCardsTime,
        //                 prizeWonId: prizeWonId,
        //                 rewardName: rewardName,
        //                 name: name,
        //                 emailTimeSent: requestCardsTime
        //             }
        //             const userGameDataRef = db.collection("usersGameData").doc(newId);
        //             batch.set(userGameDataRef, gameToCreateObject);
        //             count++;
        //             if(count % 500 === 0 || count + 2 === splitText.length){
        //                 batchUpdatesArray.push(batch.commit());
        //                 batch = db.batch();
        //             }
        //             // console.log(gameToCreateObject)
        //             // promises.push(db.collection('usersGameData').doc(newId).set(gameToCreateObject))
        //         }
        //         await Promise.all(batchUpdatesArray)
        //         // await Promise.all(promises)
        //         console.log("Done")
        //     });
        // fetch(testCsv)
        //     .then(r => r.text())
        //     .then(async text => {
        //         const splitText = text.split('\n');
        //         for(const i in splitText){
        //             if(i < 1) continue;
        //             const row = splitText[i];
        //             const splitRow = row.split(',')
        //             const id = splitRow[0];
        //             if(!id) continue;
        //             const dateObject = new Date(splitRow[1]);
        //             const startTime = dateObject.getTime();
        //             const gameName = splitRow[2] || "Scratch & Win " + (parseInt(dateObject.getMonth())+1).toString() + "/" + dateObject.getDate();
        //             const players = parseInt(splitRow[3]);
        //             const prizesWon = parseInt(splitRow[4]);
        //             const gameToCreateObject = {
        //                 id: id,
        //                 timeStamp: startTime,
        //                 totalRewardsUsed: prizesWon,
        //                 users: players,
        //                 gameName: gameName,
        //                 active: false,
        //                 currentGame: false,
        //                 isMlbGame: false
        //             }
        //             console.log(gameToCreateObject)
        //             // await db.collection('gamesList').doc(id).set(gameToCreateObject)
        //         }
        //     });
        // fetch(testCsv)
        //     .then(r => r.text())
        //     .then(async text => {
        //         const splitText = text.split('\n');
        //         for(const i in splitText){
        //             if(i < 2) continue;
        //             const row = splitText[i];
        //             const splitRow = row.split(',')
                //     const email = splitRow[0];
                //     if(!email) return;
                //     const phoneNumber = splitRow[1];
                //     let firstName = splitRow[2];
                //     let lastName = splitRow[3];
                //     if(firstName === 'null'){
                //         firstName = ""
                //     }
                //     if(lastName === 'null'){
                //         lastName = "";
                //     }
                //     let name = firstName + " " + lastName
                //     if(name === " "){
                //         name = ""
                //     }
                //     const zip = splitRow[4];
                //     const optIn = splitRow[5];
                //     const optIn2 = splitRow[6];
                //     const bday = splitRow[7]
                //     const signUpTime = new Date(splitRow[8]).getTime();
                //     const id = btoa(email.trim());
                //     const sanitizedEmail = sanitizeEmail(email.trim());
                //     const userToCreateObject = {
                //         uid: id,
                //         email: email,
                //         sanitizedEmail: sanitizedEmail,
                //         signUpTime: signUpTime,
                //         lastSignIn: signUpTime,
                //         zipCode: zip,
                //         name: name,
                //         optIn: optIn === "true",
                //         optIn2: optIn2 === "true",
                //         birthday: bday,
                //     }
                //     console.log(userToCreateObject)
                //     // await db.collection('users').doc(id).set(userToCreateObject)
                //     // console.log(lastName)
                //     // for(const t in splitRow){
                //     //     const cell = splitRow[t];
                //     //     console.log(cell)
                //     // }
            //     }
            // });
        // let count = 0;
        // const userGameData = db.collection("currentGamePlayers")
        // while(count < 2000){
        //     let newCollectionId = userGameData.doc().id;
        //     let objectToUpdate = {};
        //     objectToUpdate['timeStamp'] = new Date().getTime();
        //     objectToUpdate['id'] = newCollectionId;
        //     await userGameData.doc(newCollectionId).set(objectToUpdate);
        //     count++;
        // }
        // let promises = Array.from({length: 1000}, async (_, count) => {
        //     let user = {};
        //     let email = `leejorgensen22+${count}@gmail.com`;
        //     let uid = btoa(email);
        //     let timeStamp = new Date().getTime();
        //
        //     user['email'] = email;
        //     user['sanitizedEmail'] = sanitizeEmail(email);
        //     user['uid'] = uid;
        //     user['lastSignIn'] = timeStamp;
        //
        //     await db.collection('users').doc(uid).set(user);
        //
        //     const userResponse = {};
        //     userResponse['userId'] = uid;
        //
        //     return fetch(`http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/getScratchers`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify(userResponse),
        //     }).then(res => res.json());
        // });
        // console.log(promises.length)
        // Promise.all(promises)
        //     .then(responses => {
        //         responses.forEach(response => console.log(response));
        //     })
        //     .catch(error => {
        //         console.error(`Error in executing fetch: ${error}`);
        //     });
        // const userResponse = {};
        // let base64EncodedEmail = btoa(userEmail);
        // if(userEmail && userEmail.indexOf('@') === -1){
        //     base64EncodedEmail = userEmail;
        // }
        // userResponse['userId'] = base64EncodedEmail;
        // while(count < 100){
        //     this.Auth.fetch(`http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/getScratchers`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify(userResponse),
        //     }).then(value => {
        //         console.log(value)
        //     })
        // }
        // const users= await db.collection('gameUsers').get();
        // const usersObject = {};
        // users.forEach(function (doc) {
        //     const docData = doc.data();
        //     usersObject[docData.uid] = docData;
        // })
        // console.log(usersObject)
        // fetch(testCsv)
        //     .then(r => r.text())
        //     .then(async text => {
        //         const splitText = text.split('\n');
        //         let batch = db.batch();
        //         let counter = 0;
        //         for (const i in splitText) {
        //             if (i < 1) continue;
        //             const row = splitText[i];
        //             const splitRow = row.split(',')
        //             const uid = btoa(splitRow[5].trim());
        //             if(!usersObject[uid]){
        //                 console.log(uid, splitRow[5])
        //             }
                    // console.log(splitRow[5])
                    // const birthday = splitRow[6];
                    // const signUpTime = splitRow[7];
                    // const email = splitRow[5];
                    // const id = btoa(email.trim());
                    // let firstName = splitRow[15];
                    // let lastName = splitRow[16];
                    // const phoneNumber = splitRow[23];
                    // // const sanitizedEmail = splitRow[31];
                    // const sanitizedEmail = sanitizeEmail(email.trim());
                    // const zipCode = splitRow[4];
                    // const optIn = splitRow[5];
                    // const optIn2 = splitRow[8];
                    // if(firstName === 'NULL'){
                    //     firstName = ""
                    // }
                    // if(lastName === 'NULL'){
                    //     lastName = "";
                    // }
                    // let name = firstName + " " + lastName
                    // if(name === " "){
                    //     name = ""
                    // }
                    // console.log(birthday, new Date(signUpTime).getTime(), email, firstName, lastName, phoneNumber, sanitizedEmail, zipCode, optIn);
                    // const userToCreateObject = {
                    //     uid: id,
                    //     email: email,
                    //     sanitizedEmail: sanitizedEmail,
                    //     signUpTime: new Date(signUpTime).getTime(),
                    //     lastSignIn: new Date(signUpTime).getTime(),
                    //     zipCode: zipCode,
                    //     name: name,
                    //     optIn: optIn === "1",
                    //     optIn2: optIn2 === "1",
                    //     birthday: birthday,
                    // }
                    // let userRef = db.collection('users').doc(id);
                    // batch.set(userRef, userToCreateObject);
                    // counter++;
                    // if(counter === 50) {
                    //     await batch.commit();
                    //     batch = db.batch();
                    //     counter = 0;
                    // }
            //     }
            // });
        // this.readCsvDataAndAddDataToDB();
    }

    async readCsvDataAndAddDataToDB(){
        const rewardsGivenOutObject = await this.getPrizesWon();
        const rewardsSavedObject = await this.saveAndReturnRewards();
        const successTenantUsers = await this.saveTenantUsers(rewardsGivenOutObject[1]);
        const saveGamesData = await this.saveGames();
        const successSaveGameUsers = await this.saveGameUsers(successTenantUsers, saveGamesData, rewardsSavedObject, rewardsGivenOutObject[0]);
        alert("Successful Saving!")
    }

    async getPrizesWon(){
        const response = await fetch(prizesGiven);
        const text = await response.text();
        const splitText = await Papa.parse(text).data;
        const toReturnObject = {};
        const usersToEmailObject = {};
        for (const i in splitText) {
            if (parseInt(i) < 1) continue;
            const splitRow = splitText[i];
            const prizeWonId = this.setStringToEmpty(splitRow[0]);
            const emailSentDate = this.setStringToEmpty(splitRow[1]);
            const gameId = this.setStringToEmpty(splitRow[2]);
            const issuedDateTime = this.setStringToEmpty(splitRow[4]);
            const redeemCode = this.setStringToEmpty(splitRow[5]);
            const rewardTemplateId = this.setStringToEmpty(splitRow[6]);
            const userId = this.setStringToEmpty(splitRow[7]);
            const winningEmailSent = this.setStringToEmpty(splitRow[8])
            if(!userId) continue;
            const isRedeemed = this.setStringToEmpty(splitRow[11]);
            if(winningEmailSent){
                usersToEmailObject[userId] = winningEmailSent;
            }
            toReturnObject[gameId + "_" +userId] = {
                emailSentDate,
                gameId,
                issuedDateTime,
                redeemCode,
                rewardTemplateId,
                userId,
                isRedeemed,
                prizeWonId
            };
        }
        return [toReturnObject, usersToEmailObject];
    }

    setStringToEmpty(text){
        if(text === 'NULL' || text === '0' || !text || text === '0001-01-01 00:00:00.0000000'){
            return null;
        } else {
            return text;
        }
    }

    async saveAndReturnRewards(){
        const rewardsObject = {};
        const response = await fetch(rewardsData);
        const text = await response.text();
        const splitText = await Papa.parse(text).data;
        let batch = db.batch();
        let counter = 0;
        for (const i in splitText) {
            if (parseInt(i) < 1) continue;
            const splitRow = splitText[i];
            const rewardTemplateId = splitRow[0];
            if(!rewardTemplateId) continue;
            const description = this.setStringToEmpty(splitRow[1]) || "";
            const emailImage = this.setStringToEmpty(splitRow[2]) || "";
            let isWinning = splitRow[3] || false;
            const pictureUnderScratcher = this.setStringToEmpty(splitRow[4]);
            const rewardLink = this.setStringToEmpty(splitRow[5]);
            const rewardName = this.setStringToEmpty(splitRow[6]);
            if(splitRow[8] === "1") continue;
            const rewardButtonColor = this.setStringToEmpty(splitRow[9]);
            const rewardButtonText = this.setStringToEmpty(splitRow[10]);
            const rewardButtonTextColor = this.setStringToEmpty(splitRow[11]);
            let isRedeemable = splitRow[16];
            if(isRedeemable === 'NULL' || isRedeemable === '0' || !isRedeemable){
                isRedeemable = null;
            } else {
                isRedeemable = true
            }
            isWinning = !(isWinning === 'NULL' || isWinning === '0' || !isWinning);
            const rewardToSave = {
                id: rewardTemplateId,
                description: description,
                isRedeemable: isRedeemable,
                emailImage: emailImage || "",
                isWinning: isWinning,
                revealImage: pictureUnderScratcher || "",
                rewardName: rewardName,
                rewardDisplayName: rewardName,
                rewardLink: rewardLink,
                rewardLinkButtonText: rewardButtonText,
                rewardLinkButtonColor: rewardButtonColor,
                rewardLinkButtonTextColor: rewardButtonTextColor,
                expiresAtDateTime: null,
                expiresAtHours: null
            }
            rewardsObject[rewardTemplateId] = rewardToSave;
            let rewardsRef = db.collection('rewardsList').doc(rewardTemplateId);
            batch.set(rewardsRef, rewardToSave);
            counter++;
            if(counter === 50 || parseInt(i) === splitText.length - 2) {
                await batch.commit();
                batch = db.batch();
                counter = 0;
            }
        }
        return rewardsObject;
    }

    timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    // async loadTest(){
    //     const numberOfIterations = 100;
    //
    //     for(let i = 0; i<numberOfIterations; i++){
    //         let user = {};
    //         let email = "leejorgensen22+"+i+"@gmail.com";
    //         let uid = btoa(email);
    //         let timeStamp = new Date().getTime();
    //         user['email'] = email;
    //         user['uid'] = uid;
    //         user['lastSignIn'] = timeStamp;
    //         user['signUpTime'] = timeStamp;
    //         await db.collection('users').doc(uid).set(user);
    //     }
    //
    //     for(let t = 0; t<numberOfIterations; t++) {
    //         let email = "leejorgensen22+" + t + "@gmail.com";
    //         let uid = btoa(email);
    //
    //         const payload = {userId: uid};
    //
    //         let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/getScratchers`;
    //         if (process.env.NODE_ENV === "development") {
    //             url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/getScratchers`;
    //         }
    //
    //         // Make a POST request to the Firebase function endpoint
    //         fetch(url, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(payload),
    //         })
    //             .then(response => response.json())
    //             .then(data => {
    //                 console.log(`Response for user ${t}:`, data);
    //             })
    //             .catch(error => {
    //                 console.error(`Error for user ${t}:`, error);
    //             });
    //     }
    // }

    async saveGameUsers(successTenantUsers, saveGamesData, rewardsSavedObject, rewardsGivenOutObject){
        const response = await fetch(gameUsersData);
        const text = await response.text();
        const splitText = await Papa.parse(text).data;
        let batch = db.batch();
        let counter = 0;
        for (const i in splitText) {
            if (parseInt(i) < 1) continue;
            const splitRow = splitText[i];
            const gameId = this.setStringToEmpty(splitRow[2]);
            const userId = this.setStringToEmpty(splitRow[4]);
            const userObject = successTenantUsers[userId] || false;
            if(!userObject) continue;
            const timeStamp = this.setStringToEmpty(splitRow[3]);
            const convertedTimeStamp = new Date(timeStamp).getTime();
            if(saveGamesData[gameId]){
                saveGamesData[gameId]['users']++;
            }
            let rewardWon = {};
            if(rewardsGivenOutObject[gameId + "_" +userId]){
                saveGamesData[gameId]['prizesWon']++;
                rewardWon = rewardsGivenOutObject[gameId + "_" +userId];
            }
            const emailTimeSent = rewardWon.emailSentDate ? new Date(rewardWon.emailSentDate).getTime() : "";
            const newIdToGenerate = db.collection("usersGameData").doc().id;
            const gameUsersToCreate = {
                rewardName: rewardsSavedObject[rewardWon.rewardTemplateId] ? rewardsSavedObject[rewardWon.rewardTemplateId].rewardName : "",
                requestCardsTime: convertedTimeStamp || "",
                prizeWonId: rewardWon.prizeWonId || "",
                isRedeemed: rewardWon.isRedeemed || false,
                gameId: gameId || "",
                code: rewardWon.redeemCode || "",
                emailTimeSent: emailTimeSent,
                id: newIdToGenerate,
                email: userObject.email || "",
                name: userObject.name || "",
                uid: userObject.uid
            }
            let usersGameDataRef = db.collection('usersGameData').doc(newIdToGenerate);
            batch.set(usersGameDataRef, gameUsersToCreate);
            counter++;
            if(counter === 50 || parseInt(i) === splitText.length - 2) {
                await batch.commit();
                batch = db.batch();
                counter = 0;
                console.log("HEIT")
            }
        }
        let count = 0;
        console.log(saveGamesData)
        for (const i in saveGamesData) {
            console.log(saveGamesData[i])
            if(!saveGamesData[i].id) continue;
            const gameDataRef = db.collection('gamesList').doc(saveGamesData[i].id);

            // Add this write operation to the current batch.
            batch.set(gameDataRef, saveGamesData[i]);
            count++;

            // If we've added 500 operations to the batch, commit it and start a new one.
            if (count === 20) {
                await this.timeout(1000);
                await batch.commit();
                batch = db.batch();
                count = 0;
            }
        }
        // Commit any remaining operations in the batch.
        if (count > 0) {
            await batch.commit();
        }
    }

    async saveTenantUsers(userIdToEmail){
        const allUsersObject = {};
        const response = await fetch(tenantUsersData);
        const text = await response.text();
        const splitText = await Papa.parse(text).data;
        let batch = db.batch();
        let counter = 0;
        for (const i in splitText) {
            if (parseInt(i) < 1) continue;
            const splitRow = splitText[i];
            let email = this.setStringToEmpty(splitRow[12]);
            const oldId = this.setStringToEmpty(splitRow[3]);
            if(!email || email.indexOf('@sqwadapp.co') !== -1){
                if(userIdToEmail[oldId]){
                    email = userIdToEmail[oldId]
                }
            }
            if(!email) console.log("NO EMAIL FOUND: " + counter);
            if(!email && parseInt(i) === splitText.length - 2) console.log("NO EMAIL FOUND PLUS ENDING");
            if(!email) continue;
            console.log("Email Found")
            const uid = btoa(email.trim());
            const birthday = this.setStringToEmpty(splitRow[6]);
            const signUpTime = this.setStringToEmpty(splitRow[7]) || '2022-01-01 00:00:00.00000';
            let firstName = splitRow[15];
            let lastName = splitRow[16];
            let phoneNumber = this.setStringToEmpty(splitRow[23]) || this.extractNumber(splitRow[20]);
            const sanitizedEmail = sanitizeEmail(email.trim());
            const zipCode = splitRow[4];
            const optIn = splitRow[5];
            const optIn2 = splitRow[8];
            if(firstName === 'NULL'){
                firstName = ""
            }
            if(lastName === 'NULL'){
                lastName = "";
            }
            if(phoneNumber === 'NULL'){
                phoneNumber = "";
            }
            let name = firstName + " " + lastName
            if(name === " "){
                name = ""
            }
            const userToCreateObject = {
                uid: uid,
                email: email,
                sanitizedEmail: sanitizedEmail,
                signUpTime: new Date(signUpTime).getTime(),
                lastSignIn: new Date(signUpTime).getTime(),
                zipCode: zipCode,
                name: name,
                optIn: optIn === "1",
                optInTwo: optIn2 === "1",
                birthday: birthday,
                phoneNumber: phoneNumber
            }
            allUsersObject[oldId] = {
                uid: uid,
                email: email,
                sanitizedEmail: sanitizedEmail,
                signUpTime: new Date(signUpTime).getTime(),
                lastSignIn: new Date(signUpTime).getTime(),
                zipCode: zipCode,
                name: name,
                optIn: optIn === "1",
                optIn2: optIn2 === "1",
                birthday: birthday,
                phoneNumber: phoneNumber,
                oldId
            };
            let userRef = db.collection('users').doc(uid);
            batch.set(userRef, userToCreateObject);
            counter++;
            if(counter === 100 || parseInt(i) === splitText.length - 2) {
                await this.timeout(1000);
                await batch.commit();
                batch = db.batch();
                counter = 0;
            }
        }
        return allUsersObject;
    }

    extractNumber = (str) => {
        const matched = str.match(/(\d+)@/); // Find a sequence of numbers followed by "@"
        if (matched && matched[1]) {
            return matched[1];
        }
        return "";
    }

    async saveGames(){
        const gamesToSave = {};
        const response = await fetch(gamesData);
        const text = await response.text();
        const splitText = await Papa.parse(text).data;
        for (const i in splitText) {
            if (parseInt(i) < 1) continue;
            const splitRow = splitText[i];
            const gameName = this.setStringToEmpty(splitRow[9]);
            const id = this.setStringToEmpty(splitRow[0]);
            const timeStamp = this.setStringToEmpty(splitRow[5]);
            const endTimeStamp = this.setStringToEmpty(splitRow[1]);
            const convertedTimeStamp = new Date(timeStamp).getTime()
            const convertedEndTimeStamp = new Date(endTimeStamp).getTime()
            gamesToSave[id] = {
                gameName: gameName || "Created Game " + i,
                currentGame: false,
                prizesWon: 0,
                users: 0,
                timeStamp: convertedTimeStamp,
                startTime: convertedTimeStamp,
                endTime: convertedEndTimeStamp,
                id: id
            };
        }
        return gamesToSave;
    }

    async getGames(start = 0, end){
        if(!start){
            start = 0;
        }
        let queryString = db.collection('gamesList').orderBy('timeStamp', 'desc').where('timeStamp', '>=', start);
        if(end){
            queryString = queryString.where('timeStamp', '<=', end);
        }
        const gamesListRef = await queryString.get();
        let prizes = 0;
        let gameUsers = 0;
        const gamesList = [];
        gamesListRef.forEach(doc => {
            const gameData = doc.data();
            if(gameData.autoStatus !== 'scheduled') {
                gamesList.push(gameData);
            }
        })
        for(const i in gamesList){
            const gameToLookAt = gamesList[i];
            if(gameToLookAt.currentGame){
                const currentGamePlayersRef = await db.collection('currentGamePlayers').get();
                gameUsers += currentGamePlayersRef.size;
                prizes += gameToLookAt.totalRewardsUsed;
                gameToLookAt.users = currentGamePlayersRef.size;
            } else {
                prizes += gameToLookAt.prizesWon || gameToLookAt.totalRewardsUsed || 0;
                gameUsers += gameToLookAt.users || 0;
            }
        }
        this.setState({
            prizesWon: prizes,
            gameUsers: gameUsers,
            gamesToShowList: gamesList
        })
        // const data = await base.get('testData/testData', {
        //     context: this
        // })
        // console.log(data)
        // queryString.once('value', function (snapshot) {
        //     let prizesCount = currentGamePrizes;
        //     let gameUsersCount = currentGameUsers;
        //     let gamesArray = [];
        //     let index = 0;
        //     snapshot.forEach(function (data) {
        //         index++;
        //         prizesCount += data.val().prizes || 0;
        //         gameUsersCount += data.val().users || 0;
        //         if(snapshot.numChildren() - vm.state.itemsPerPage < index){
        //             gamesArray.unshift(data.val())
        //         }
        //     })
        //     if(currentGame){
        //         gamesArray.unshift(currentGame)
        //     }
        //     vm.setState({
        //         loading: false,
        //         games: gamesCount + snapshot.numChildren(),
        //         prizesWon: prizesCount,
        //         gameUsers: gameUsersCount,
        //         gamesToShowList: gamesArray
        //     })
        // });
    }

    async getUsers(start=0, end, returnUsers=false, asObject=false){
        let queryString = db.collection("users").orderBy('signUpTime');
        if(start){
            queryString = queryString.where('signUpTime', '>=', start);
        }
        if(end){
            queryString = queryString.where('signUpTime', '<=', end);
        }
        if(returnUsers){
             const getUsersRef = await queryString.get();
            if(asObject){
                return getUsersRef;
            } else {
                const usersArray = [];
                getUsersRef.forEach(doc => {
                    usersArray.push(doc.data())
                })
                return usersArray;
            }
        } else {
            let optInCounts = 0;
            const getUsersAndReturnSnapshot = await queryString.get();
            getUsersAndReturnSnapshot.forEach(function (data){
                const user = data.data();
                if(user.optIn || user.optInTwo || user.optInThree || user.optInFour){
                    optInCounts++;
                }
            })
            this.setState({
                loading: false,
                users: getUsersAndReturnSnapshot.size,
                optIns: optInCounts
            })
        }
    }

    async getUserData(onlyOptIns){
        this.setState({
            loading: true
        })
        let searchStart = this.state.searchStart || 0;
        let searchEnd = this.state.searchEnd;
        if(typeof searchStart === "object"){
            searchStart = searchStart.getTime()
        }
        if(searchEnd && typeof searchEnd === "object"){
            searchEnd = searchEnd.getTime()
        }
        let csv = process.env.REACT_APP_CLEAN_CLIENT_NAME || process.env.REACT_APP_FIREBASE_PROJECT_ID;
        const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0];
        csv+=",Item Drop\n,\n"
        csv+="Date Data Downloaded,"+date_got+"\n";
        csv+="From,"+(searchStart?convertTimeStampToHumanReadable(searchStart):"")+"\n";
        csv+="To,"+(searchEnd?convertTimeStampToHumanReadable(searchEnd):"")+"\n,\n";
        csv += 'Email,First Name,Last Name,Zip Code,Birthday,Address,Phone Number,Opt-In,Opt-In 2,Country,YesNoQuestion,Signed Up\n';
        let timeFrameUsers = await this.getUsers(searchStart, searchEnd, true);
        for(let timeFrameUserIndex in timeFrameUsers){
            let user = timeFrameUsers[timeFrameUserIndex];
            if(onlyOptIns){
                if(!user.optIn && !user.optInTwo && !user.optInThree && !user.optInFour){
                    continue;
                }
            }
            let {firstName, secondName} = findFirstAndLastName(user.name);
            csv += (user.email || user.uid || "") + ",";
            csv += (cleanCSVText(firstName) || "") + ",";
            csv += (cleanCSVText(secondName) || "") + ",";
            csv += (cleanCSVText(user.zipCode) || "") + ",";
            csv += (user.birthday || "") + ",";
            csv += (user.address || "") + ",";
            csv += (user.phoneNumber || "") + ",";
            csv += (user.optIn || "") + ",";
            csv += (user.optInTwo || "") + ",";
            csv += (user.country || "") + ",";
            csv += (user.yesNoQuestions||"") + ",";
            csv += (user.signUpTime?convertTimeStampToHumanReadable(user.signUpTime):"")
            csv += "\n";
        }
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        let cleanString = (searchStart?"_"+convertTimeStampToHumanReadable(searchStart):"")+(searchEnd?"_"+convertTimeStampToHumanReadable(searchEnd):"");
        cleanString = "item_drop_" + cleanString.replace(/[|&;$%@"<>()+,]/g, "").toLowerCase()+".csv"
        hiddenElement.download = cleanString;
        hiddenElement.click();
        this.setState({
            loading:false
        })
    }

    async downloadIntenseGameData(game){
        this.setState({
            loading: true
        })
        let csv = (process.env.REACT_APP_CLEAN_CLIENT_NAME || process.env.REACT_APP_FIREBASE_PROJECT_ID) + ',' + '\n""\n';
        let date_downloaded = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0];
        let usersGameDataRef = await db.collection("usersGameData").orderBy('requestCardsTime', 'desc').where('gameId', '==', game.id).get();
        let users = {};
        let usersRef = await this.getUsers(0, null, true, true);
        let prizeWonRef = await db.collection('prizeWon').where('gameId', '==', game.id).get();
        let redeemedPrizes = {};
        prizeWonRef.forEach(doc => {
            redeemedPrizes[doc.data().sendPrizeId] = doc.data();
        })
        usersRef.forEach(doc => {
            users[doc.data().uid] = doc.data();
        });
        // let users = {};
        let totalPrizes = 0;
        usersGameDataRef.forEach(function(snapshot){
            if(snapshot.data().prizeWonId){
                totalPrizes++;
            }
        })
        let searchStart = this.state.searchStart || 0;
        let searchEnd = this.state.searchEnd;
        if(typeof searchStart === "object"){
            searchStart = searchStart.getTime()
        }
        if(searchEnd && typeof searchEnd === "object"){
            searchEnd = searchEnd.getTime()
        }

        // for(const userIndex in gameAnswers){
        //     const userInfo = gameAnswers[userIndex];
        //     if(userInfo.rewardSent){
        //         totalPrizes++;
        //     }
        // }
        // const users = await appDatabasePrimaryFunctions.ref('users').once('value').then(function(snapshot){
        //     if(snapshot.exists()){
        //         return snapshot.val();
        //     } else {
        //         return {}
        //     }
        // })
        csv+= 'Date Data Downloaded,'+date_downloaded+'\n';
        csv+= 'Game Name,'+game.gameName+'\n';
        csv+= 'Start Time,'+(searchStart?convertTimeStampToHumanReadable(searchStart):"")+'\n';
        csv+= 'End Time,'+(searchEnd?convertTimeStampToHumanReadable(searchEnd):"")+'\n';
        csv+= 'Prizes Won,'+totalPrizes.toString()+'\n""\n';
        csv+= 'Email,First Name,Last Name,Zip Code,Birthday,Address,Phone Number,Opt-In,Opt-In 2,Country,YesNoQuestion,Time Stamp,Email Sent Time,Reward,Code,Has been Redeemed\n';
        // for(const userIndex in gameAnswers){
        usersGameDataRef.forEach(function (snapshot){
            const userInfo = snapshot.data();
            const moreUserData = users[userInfo.uid] || {};
            let {firstName, secondName} = findFirstAndLastName(moreUserData.name);
            csv+= (userInfo.email || userInfo.uid || "")
                +','+(cleanCSVText(firstName) || "")
                +','+(cleanCSVText(secondName) || "")
                +','+(cleanCSVText(moreUserData.zipCode) || "")
                +','+(moreUserData.birthday || userInfo.birthday ||"")
                +','+(moreUserData.address || userInfo.address ||"")
                +','+(moreUserData.phoneNumber || userInfo.phoneNumber ||"")
                +','+(moreUserData.optIn||"")
                +','+(moreUserData.optInTwo||"")
                +','+(moreUserData.country||"")
                +','+(moreUserData.yesNoQuestions||"")
                +','+(userInfo.requestCardsTime ? convertTimeStampToHumanReadable(userInfo.requestCardsTime) : "")
                +','+(userInfo.emailTimeSent ? convertTimeStampToHumanReadable(userInfo.emailTimeSent.seconds || userInfo.emailTimeSent) : "")
                +','+(cleanCSVText(userInfo.rewardName) || "NONE")
                +','+(cleanCSVText(userInfo.code) || "-")
                +','+(userInfo.isRedeemed || (redeemedPrizes[userInfo.prizeWonId] && redeemedPrizes[userInfo.prizeWonId].redeemed) ? "Yes" : "No") + '\n'
        })
        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        let cleanString = game.gameName.replace(/[|&;$%@"<>()+,. ]/g, "").toLowerCase();
        hiddenElement.download = "gamedata_"+cleanString+".csv";
        hiddenElement.click();
        this.setState({
            loading: false
        })
    }

    async downloadAllGamesData() {
        this.setState({ loading: true });

        const { searchStart, searchEnd } = this.state;
        // If user hasn't selected a start/end date, set some defaults:
        const start = searchStart ? searchStart.getTime() : 0;
        const end = searchEnd ? searchEnd.getTime() : new Date().getTime();

        // 1) Get all games within [start, end] from Firestore
        let gamesQuery = db
            .collection('gamesList')
            .orderBy('timeStamp', 'desc')
            .where('timeStamp', '>=', start);

        if (end) {
            gamesQuery = gamesQuery.where('timeStamp', '<=', end);
        }

        const gamesSnapshot = await gamesQuery.get();
        const gamesList = [];
        gamesSnapshot.forEach(doc => {
            gamesList.push(doc.data());
        });

        // 2) Fetch all users so we can look them up quickly
        // Using your existing helper: getUsers(start=0, end, returnUsers=true, asObject=true)
        // which returns a Firestore QuerySnapshot when asObject=true
        const usersSnapshot = await this.getUsers(0, null, true, true);
        const usersData = {};
        usersSnapshot.forEach(doc => {
            // doc.data().uid is how you store the user’s ID in that document
            usersData[doc.data().uid] = doc.data();
        });

        // 3) Build an object of all redeemed prize docs for quick "isRedeemed" checks
        //    Because Firestore only allows `where('in', ...)` on up to 10 items at once,
        //    we chunk the game IDs to avoid errors if there are many games.
        const gameIds = gamesList.map(g => g.id);
        const chunkSize = 10;
        const redeemedPrizes = {}; // key: prizeWonId, value: doc.data()

        for (let i = 0; i < gameIds.length; i += chunkSize) {
            const chunk = gameIds.slice(i, i + chunkSize);
            // If chunk is empty, skip
            if (!chunk.length) continue;

            const prizeSnapshot = await db
                .collection('prizeWon')
                .where('gameId', 'in', chunk)
                .get();

            prizeSnapshot.forEach(doc => {
                const data = doc.data();
                // If you store the doc’s ID in `prizeWonId`, use that as the key
                if (data.prizeWonId) {
                    redeemedPrizes[data.prizeWonId] = data;
                }
            });
        }

        // 4) Prepare our CSV header
        let csv = (process.env.REACT_APP_CLEAN_CLIENT_NAME || process.env.REACT_APP_FIREBASE_PROJECT_ID) + '\n\n';
        const dateDownloaded = new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000
        )
            .toISOString()
            .split('T')[0];

        csv += `Date Data Downloaded,${dateDownloaded}\n`;
        csv += `From,${start ? convertTimeStampToHumanReadable(start) : ''}\n`;
        csv += `To,${end ? convertTimeStampToHumanReadable(end) : ''}\n\n`;

        // Same columns you have in `downloadIntenseGameData`
        csv += [
            'Email',
            'First Name',
            'Last Name',
            'Zip Code',
            'Birthday',
            'Address',
            'Phone Number',
            'Opt-In',
            'Opt-In 2',
            'Country',
            'YesNoQuestion',
            'Game Name',
            'Time Stamp',
            'Email Sent Time',
            'Reward',
            'Code',
            'Has Been Redeemed',
        ].join(',') + '\n';

        // 5) For each game, get the `usersGameData` docs
        //    This replicates what `downloadIntenseGameData` does for a single game
        for (const game of gamesList) {
            const userGameDataSnap = await db
                .collection('usersGameData')
                .where('gameId', '==', game.id)
                .orderBy('requestCardsTime', 'desc')
                .get();

            userGameDataSnap.forEach(snapshot => {
                const userInfo = snapshot.data();
                const moreUserData = usersData[userInfo.uid] || {};

                const { firstName, secondName } = findFirstAndLastName(moreUserData.name);

                // Build each row; reuse your CSV cleaning and time conversion helpers
                csv += [
                    userInfo.email || userInfo.uid || '',
                    cleanCSVText(firstName) || '',
                    cleanCSVText(secondName) || '',
                    cleanCSVText(moreUserData.zipCode) || '',
                    moreUserData.birthday || userInfo.birthday || '',
                    moreUserData.address || userInfo.address || '',
                    moreUserData.phoneNumber || userInfo.phoneNumber || '',
                    moreUserData.optIn || '',
                    moreUserData.optInTwo || '',
                    moreUserData.country || '',
                    moreUserData.yesNoQuestions || '',
                    cleanCSVText(game.gameName) || '',
                    userInfo.requestCardsTime ? convertTimeStampToHumanReadable(userInfo.requestCardsTime) : '',
                    userInfo.emailTimeSent
                        ? convertTimeStampToHumanReadable(
                            userInfo.emailTimeSent.seconds || userInfo.emailTimeSent
                        )
                        : '',
                    cleanCSVText(userInfo.rewardName) || 'NONE',
                    cleanCSVText(userInfo.code) || '-',
                    // Check both userInfo.isRedeemed and the `prizeWon` doc
                    (userInfo.isRedeemed ||
                        (userInfo.prizeWonId &&
                            redeemedPrizes[userInfo.prizeWonId] &&
                            redeemedPrizes[userInfo.prizeWonId].redeemed))
                        ? 'Yes'
                        : 'No',
                ]
                    .map(item => (item === null ? '' : item)) // handle any nulls
                    .join(',') + '\n';
            });
        }

        // 6) Trigger the CSV download
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = `all_games_data_${dateDownloaded}.csv`;
        hiddenElement.click();

        this.setState({ loading: false });
    }

    searchData(){
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if(searchStart){
            searchStart = searchStart.getTime();
        }
        if(searchEnd){
            searchEnd = searchEnd.getTime();
        }
        this.setState({
            loading: true,
            itemsPerPage: this.state.itemsPerPage
        }, ()=>{

        })
        this.getGames(searchStart,searchEnd)
        this.getUsers(searchStart,searchEnd)
    }

    onNextPage() {
        const vm = this;
        const newAmountToShow = this.state.itemsToShow + this.state.itemsPerPage;
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if(searchStart){
            searchStart = searchStart.getTime();
        }
        if(searchEnd){
            searchEnd = searchEnd.getTime();
        }
        let queryString = appDatabasePrimaryFunctions.ref('gamesList').orderByChild('timeStamp').limitToLast(newAmountToShow).startAt(searchStart);
        if (searchEnd) {
            queryString = queryString.endAt(searchEnd)
        }
        queryString.once('value', function (snapshot) {
            let gamesArray = [];
            snapshot.forEach(function (data) {
                gamesArray.unshift(data.val())
            })
            vm.setState({
                itemsToShow: newAmountToShow,
                gamesToShowList: gamesArray
            });
        })
    }

    render() {
        const scoreboardOnly = this.props.scoreboardOnly;
        const totalUsers = this.state.users || 0;
        const optIns = this.state.optIns || 0;
        const gameUsers = this.state.gameUsers || 0;
        const prizesWon = this.state.prizesWon || 0;
        const fan_side_link = process.env.REACT_APP_FAN_LINK || "";
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <div className="row" style={{width:'100%'}}>
                    <div className="col-md-11">
                        <p className="admin-header-text" style={{marginTop:'10px'}}>Game link: <span style={{fontSize:'20px'}}>{fan_side_link}</span></p>
                    </div>
                    <div className="col-md-1">
                        <p style={{color: "black"}}>
                            v{process.env.REACT_APP_VERSION}
                        </p>
                    </div>
                    {/*<div className="col-md-1">*/}
                    {/*    <button onClick={() => this.loadTest()}>Load Test</button>*/}
                    {/*</div>*/}
                </div>
                <form className="form-horizontal" style={{marginLeft: 20, marginBottom:10}}>
                    <div className="row g-3 align-items-center">
                        <div className="col-auto">
                            <DatePicker
                                isClearable
                                selected={this.state.searchStart}
                                onChange={date => {
                                    if(date) date.setHours(0,0,0,0);
                                    this.setState({searchStart: date});
                                }}
                            />
                        </div>

                        <div className="col-auto">
                            <DatePicker
                                isClearable
                                selected={this.state.searchEnd}
                                onChange={date => {
                                    if(date) date.setHours(23,59,59,999);
                                    this.setState({searchEnd: date});
                                }}
                            />
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-primary btn-admin" type="button" onClick={()=>this.searchData()}>Search</button>
                        </div>
                        {!scoreboardOnly &&
                            <div className="col-auto">
                                <button className="btn btn-primary btn-admin" type="button" onClick={()=>this.downloadAllGamesData()}>Download All Games</button>
                            </div>
                        }
                    </div>
                </form>
                <div className="admin-grid-container four-columns">
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{totalUsers}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Accounts Created</span>
                                    </div>
                                    {/*<div className="col-md-4" style={{fontSize: 40, alignSelf: "center"}}>*/}
                                    {/*    <i className="fa fa-arrow-circle-o-down" aria-hidden="true" style={{color: "white", cursor: "pointer"}} onClick={() => this.getUserData()}/>*/}
                                    {/*</div>*/}
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{optIns}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Opt Ins</span>
                                    </div>
                                    <div className="col-md-4" style={{fontSize: 40, alignSelf: "center"}}>
                                        <i className="fa fa-arrow-circle-o-down" aria-hidden="true" style={{color: "white", cursor: "pointer"}} onClick={() => this.getUserData(true)}/>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{gameUsers}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Game Users</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{prizesWon}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Prizes Won</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                </div>
                <div style={{margin:20}}>
                    {this.state.gamesToShowList && this.state.gamesToShowList.length > 0 &&
                    <>
                        {
                            this.state.gamesToShowList.map(function (item, index) {
                                return (
                                    <div key={index} className="card card-styles text-xs-center" style={{marginBottom: 10}}>
                                        <div className="card-body" style={{boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px"}}>
                                            <div className="row" style={{alignItems: 'center'}}>
                                                <div className="col-md-2">
                                                    <span style={{color: 'black', fontSize: 14}}>{convertTimeStampToHumanReadable(item.timeStamp)}</span>
                                                </div>
                                                <div className="col-md-3" style={{fontSize: 30}}>
                                                    {item.gameName} {item.autoStatus === "scheduled" && "- Scheduled"}
                                                </div>
                                                <div className="col-md-3" style={{textAlign: 'right'}}>
                                                    <span style={{fontSize: 25}}>{item.users || item.gameUsers || 0}</span>
                                                    <span style={{color: 'black', fontSize: 15, marginRight: 15}}>Played</span>
                                                    <span style={{fontSize: 25}}>{item.prizes || item.prizesWon || item.totalRewardsUsed || 0}</span>
                                                    <span style={{color: 'black', fontSize: 15}}>Prizes Won</span>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row" style={{fontSize: 20, justifyContent: 'center'}}>
                                                        {item.prizesWon !== null && !item.currentGame && (item.users > 0 || item.gameUsers > 0) &&
                                                            <button onClick={() => this.downloadIntenseGameData(item)} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                                <span className="fa fa-arrow-circle-down"/> Download Game Data
                                                            </button>
                                                        }
                                                        {item.currentGame &&
                                                            <button onClick={() => window.location.href='/setupgame'} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                                Go To Current Game
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }, this)
                        }
                        {this.state.games > this.state.gamesToShowList.length &&
                            <button className="btn btn-primary" onClick={()=>this.onNextPage()}>
                                More
                            </button>
                        }
                    </>
                    }
                </div>
            </div>
         </div>
        );
    }
}

export default AdminHome
